export default {
    editItem(data) {
    },
    store() {
        this.form.journal = this.journal;
        this.form.note_type = 'dr';
        this.journal.ledger_head_id = this.form.items.data[0] ? (this.form.items.data[0].ledger_accounts[0] ? this.form.items.data[0].ledger_accounts[0].ledger_head_id : '') : '';
        this.isLoading = true
        this.$rest.post('/api/drcr-note', this.form)
        .then((res) => {
            this.form.reset();
            this.form.items = [];
            this.$events.fire('notification', {message: res.data.message});
        })
        .finally(()=> {
            this.isLoading = false

        })
            // .then(res => {
            //     this.form.reset();
            //     this.form.items = [];
            //     this.$events.fire('notification', {message: res.data.message});
            //     this.isLoading = false
            // }
        // );
    },

    storeLedger() {
        this.ledgerForm.store().then(({data}) => {
            this.getLedgerHeads(data.data.id);
        })
    },

    // Journal Types
    getJournalTypes() {
        this.$rest.get('/api/journal-type').then(({data}) => {
            this.pr_type = data.data.filter(item => item.pr_type === 'receipt').map(item => {
                return {value: item.id, text: item.name}
            });
        })
    },

    // account heads
    getAccountHeads() {
        this.$rest.get('/api/account-head').then(({data}) => {
            this.accountHeads = data.data.map(item => ({value: item.id, text: item.name}));
        })
    },

    getLedgerHeads(id) {
        this.$rest.get('/api/ledger-head').then(({data}) => {
            this.ledgerHeads = data.data.map(item => ({value: item.id, text: item.name}));
            if (![undefined, null].includes(id)) {
                let len = this.form.ledger.length;
                if (len > 0) this.form.ledger[len - 1].ledger_head_id = id;
                else this.form.ledger[0].ledger_head_id = id;
            }
        })
    },

    addRow: function () {
        if ([undefined, null].includes(this.form.ledger)) {
            this.form.ledger = [{ledger_head_id: null, total_amount: 0, tax: 0, net_amount: 0}];
        } else {
            this.form.ledger.push({ledger_head_id: null, total_amount: 0, tax: 0, net_amount: 0});
        }
    },
    removeElement: function (row) {
        var index = this.form.ledger.indexOf(row);
        this.form.ledger.splice(index, 1);
    },

    ledgerChange(data) {
        let head = this.ledgerHeads.find(item => item.value == data.ledger_head_id);
        data.ledger_head_name = head.text;
    },

    getSerialNumber() {
        this.$rest.get('/api/payment-receipt/serial?type=receipt').then(({data}) => {
            this.form.serial_no = data.data.serial;
        });
    },

    lazySum(row) {
        return new Promise((resolve, reject) => {
            row.net_amount = parseFloat(row.total_amount) + parseFloat(row.tax);
            resolve(row);
        })
    },

    sumNetAmount(item) {
        if ([undefined, null].includes(item)) {
            if (typeof this.form.ledger === 'object' && this.form.ledger !== null) this.payment_mode_type.final_amount = this.form.ledger.map(it => parseFloat(it.net_amount)).reduce((x, y) => x + y, 0)
        } else {
            this.lazySum(item).then(response => {
                if (isNaN(item.net_amount)) item.net_amount = 0
                this.journal.final_amount = this.form.ledger.map(it => parseFloat(it.net_amount)).reduce((x, y) => x + y, 0)
            })
        }
    },

    setEntryDate() {
        if (this.journal.entry_date == null) {
            let date = this.$converter.todayNP();
            this.journal.entry_date = `${date.year}-${date.month.pad(2)}-${date.day.pad(2)}`;
        }
    },
    submit() {
        let fiscal_year_id = 1
        this.$rest.get('/api/drcr-note/ledgers?serialNo=' + this.form.serial_no + '&fiscal_year_id='+fiscal_year_id).then(({data}) => {
            this.form.items = data;
            if (data.data.length > 0) this.tag = true;
            else this.tag = false;
        })
    },

}
